import React, { Component } from 'react';
import { hashToSchema } from '../config/MenuConfig';

class ContentHeader extends Component {
  state = { schema: {}, data: {}, title:'' }

  validateData(props) {
    if (!props) {
      props = this.props;
    }
    if(props.title in hashToSchema && hashToSchema[props.title].label){
      this.setState({ title: hashToSchema[props.title].label})
    }else{
      this.setState({ title: global.capitalize(props.title) });
    }
  }

  componentDidMount() {
    this.validateData(this.props);
  }
  
  componentDidUpdate(newProps){
    if(newProps !== this.props){
      this.validateData()
    }
  }

  render() {

    return (
      
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2 align-items-center justify-content-between">
              <div className="col-sm-6">
                <h1 className="m-0">{ this.state.title }</h1>
              </div>
              <div className='col-auto'>
                { this.props.actions }
              </div>
            </div>
          </div>
        </div>
      
    );
  }
}

export default ContentHeader;
