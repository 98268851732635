import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
class ConfirmationDialog extends React.Component {
  
  render() {
    const { isOpen, title, message, onConfirm, onClose} = this.props

    if (!isOpen) {
      return null;
    }

    return (<>
        <Modal
          isOpen={isOpen}
          backdrop={true}
          keyboard={true}
          centered={true}
        >
          <ModalHeader toggle={onClose}>{title}</ModalHeader>
          <ModalBody>{ <p dangerouslySetInnerHTML={{ __html: message }} /> }</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={onConfirm}>Bestätigen</Button>{' '}
            <Button color="secondary" onClick={onClose}>Abbrechen</Button>
          </ModalFooter>
      </Modal>
    </>
    );
  }
}

export default ConfirmationDialog;