import React, { Component } from 'react';

import ReactForm from "@rjsf/core";
import validator from '@rjsf/validator-ajv8';
import { fetchApi } from '../utils/DatabaseHelper';
import { formInputRename } from '../config/TableViewConfig';

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = { schema: {}, data: {} };
    const RelationalSelectWidget = (props) => {
      return (
        <button id="custom" className={props.value ? "checked" : "unchecked"} onClick={() => props.onChange(!props.value)}>
          {String(props.value)}
        </button>
      );
    };
    const UploadWidget = (props) => {
      console.log('upload rendering', this.state);
      let exists = props.value && props.value.length > 0;
      let content = "";
      if (exists) {
        content = <a className="si-btn btn-default" download={this.state.data.name} href={props.value} target="_blank">Download</a>;
      } else {
        content = <input type="file"
          className="si-btn btn-default"
          required={props.required}
          onChange={(event) => {
            const file = event.target.files[0];
            const reader = new FileReader();
            this.filename = file.name;
            reader.addEventListener("load", () => {
              this.setState({ data: { ...this.state.data, name: this.filename } }, () => props.onChange(reader.result))
            }, false);

            if (file) {
              reader.readAsDataURL(file);
            }

          }} />;
      }
      return (
        <div>
          {content}
        </div>
      )
    };

    this.widgets = {
      RelationalSelectWidget: RelationalSelectWidget,
      UploadWidget: UploadWidget
    }
  }

  getOneToManyRelations() {
    let relatedOneToManyResources = [];

    Object.keys(this.props.schemas).forEach(resourceName => {
      if (resourceName.indexOf('_' + this.props.resourceName) !== -1) {
        console.log('one to many', resourceName, this.props.resourceName);
        relatedOneToManyResources.push(resourceName);
      }
    })
    return relatedOneToManyResources;
  }

  evaluateBeforeFormData(data) {
    if (data.id && this.props.resourceName === 'media') {
      this.filename = data.name;
    }
  }
  validate(props) {
    console.log(this.getOneToManyRelations());

    if (props.resourceId) {
      // fetch(global.api_url + props.resourceName + '/' + props.resourceId)
      //   .then(res => res.json())
      //   .then(data => {
      //     console.log('set form data', data);
      //     this.evaluateBeforeFormData(data);
      //     this.setState({ data, schema: props.schema, oneToManyRelations: this.getOneToManyRelations() })
      //   });
      fetchApi(props.resourceName, props.resourceId).then( data => {
        console.log('set form data', data);
        this.evaluateBeforeFormData(data);
        this.setState({ data, schema: props.schema, oneToManyRelations: this.getOneToManyRelations() })
      });
    } else {
      this.setState({ schema: props.schema, data: {} })
    }

  }
  componentDidMount() {
    this.validate(this.props);
  }
  componentDidUpdate(newProps){
    if(newProps !== this.props){
      this.validate(newProps)
    }
  }

  saveOrUpdate() {
    console.log('start 1', this, this.data, this.props.resourceName);
    if (this.data) {
      if (this.data.id) {
        return fetch(global.api_url + this.props.resourceName + '/' + this.props.resourceId, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.data)
        });

      } else {
        return fetch(global.api_url + this.props.resourceName, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.data)
        });
      }
    }
  }
  /**
   * 
   * <button type="button" className="btn btn-default" onClick={() => this.props.cancel()}>Abbrechen</button>
            <button type="submit" className="btn btn-primary" onClick={() => this.saveOrUpdate()}>Speichern</button>
   */
  render() {
    let uiSchema = {
      id: {
        // "ui:disabled": true,
        "ui:widget": 'hidden'
      },
      datei: {
        "ui:widget": this.widgets.UploadWidget
      },
      kommentar: {
        "ui:widget": 'textarea'
      },
    };

    //Wir passen Labels ggf an wenn in formInputRenam @config/TableViewConfig.js
    if(this.state.schema.properties) {
      for(const [key, value] of Object.entries(formInputRename) ){
        // console.log('kelog', key, value)
        // if( this.state.schema.properties.hasOwnProperty(key)){
        if( Object.prototype.hasOwnProperty.call(this.state.schema.properties, key)){
          uiSchema[key] = {"ui:title": value,}
        }
      }
    }

    console.log('one to many', this.state.oneToManyRelations);
    /**
     * Bei oneTOMany Relationen zeigen wir diese mit an
     * 
     * @since 1.5.2 sollen die Zuordnungen nicht mehr als Tabelle im Formular gezeigt werden.
     */
    // let oneToManyRelations = this.state.oneToManyRelations && this.state.oneToManyRelations.map(relationTable => {
    //   let relatedResourceName = relationTable.split('_')[0];
    //   let relatedResourceId = this.state.data.id;
    //   let relatedSchema = this.props.schemas[relatedResourceName];
    //   console.log('one to many rebuild', relatedResourceName, relationTable, relatedResourceId);
    //   return <TableBox
    //     resourceName={relatedResourceName}
    //     schema={relatedSchema}
    //     relatedResourceId={relatedResourceId}
    //     relationTable={relationTable}
    //     disableSearch={true}
    //     disableFilter={true}
    //     excludeFields={[this.props.resourceName + 'Id']}
    //     sourceOtherModal={true}
    //     renderers={{
    //       datei: (datei) => {
    //         return <a href={global.api_url + 'datei/' + datei.id + '?authorization=' + global.auth.authToken.token + '&binary=true'} target='_blank'>Download</a>
    //       }
    //     }}
    //   >
    //   </TableBox>;
    // });
    console.log(this.state.schema)
    return (
      <div className="formWrapper">
        <ReactForm
          schema={this.state.schema}
          validator={validator}
          formData={this.state.data}
          uiSchema={uiSchema}
          onSubmit={
            data => {
              console.log('data', data);
            }
          }
          onChange={data => {
            console.log('changed form data', data);
            //verzoegerung sonst permanente speicherung bei jeder eingabe
            clearTimeout(this.editTimer);
            this.editTimer = setTimeout(() => {
              //save for on changesave
            }, 1000);
            this.data = data.formData;
          }}
        >
          <span></span>
        </ReactForm>
        {/* {oneToManyRelations} */}

      </div>
    );
  }
}

export default Form;
