import React, { Component } from 'react';

class Box extends Component {
  state = { schema: {}, data: {} }

  validateData() { }
  componentDidMount() {
    this.validateData(this.props);
  }
  
  componentDidUpdate(newProps){
    if(newProps !== this.props){
      this.validateData(newProps)
    }
  }

  render() {
    return (
      <section className="col p-0 connectedSortable tableBox">
        <div className="card">
          <div className="card-header align-items-center">
            <div className="row justify-content-end">
              {/* <h3 className="card-title col"> //Rausgenommen wegen: https://gitlab.cloud.sobek-innovations.de/alexandra.sobek/vorsorgekartei/-/issues/203#note_20388
                <i className="ion ion-clipboard mr-1"></i>
                {global.capitalize(this.props.title)}
              </h3> */}
              <div className="card-tools col-auto d-flex">
                {this.props.disabledSearch === true && <input type="text" name="table_search" className="form-control float-right" placeholder="Suche" onChange={(e) => {
                  clearTimeout(this.searchtimer);
                  this.searchtimer = setTimeout(() => {
                    this.props.onSearch(e.target.value);
                  })
                }}/>}
                { (this.props.disableFilter === false) ? <button className="btn btn-default btn-sm float-right active" data-toggle="button" aria-pressed="false" style={{marginLeft: 20}} onClick={this.props.onFilter}>Filter</button> : ""}
                { (this.props.onRowNew) ? <button className="btn btn-default btn-sm float-right" data-toggle="button" aria-pressed="true" style={{marginLeft: 10}} onClick={() => this.props.onRowNew()}>Neu</button> : ""}
              </div>
            </div>
            <div className="card-select-box row mt-3">
              <div className="col">
                {this.props.toolBox}
              </div>
            </div>
          </div>

          <div className="card-body" style={{maxWidth: '100%', overflowX: 'auto'}}>
            {this.props.children}
          </div>
        </div>
      </section>
    );
  }
}

export default Box;


