import React, { Component } from "react";
import { fetchApi } from "../../utils/DatabaseHelper";
import { MITARBEITER_UEBERSICHT } from "../../utils/TableConstanten";
import { PieChart, Pie, ResponsiveContainer, Cell, Label, BarChart, CartesianGrid, XAxis, YAxis, Bar } from "recharts";
import CustomLabel from "./CustomLabel";
import WidgetFilterBox from "./WidgetFilterBox";
import moment from "moment";

const color = {
    red: '#DC2626',
    yellow: '#FBBF24',
    green: '#a3e635'
}

class PieChartOverview extends Component{

    constructor(props){
        super(props);

        this.setSelected = this.setSelected.bind(this);
    }

    defaultData = [
      { title: 'red', value: 1, color: color.red },
      { title: 'yellow', value: 1, color: color.yellow },
      { title: 'green', value: 1, color: color.green },
    ];

    state = {
        maxUntersuchungen: 0,
        data: this.defaultData,
        donut: this.props.donut,
        yearly: this.props.yearly,
        activeIndex: null,
    }

    validate() {
        this.setState({data: this.defaultData, maxUntersuchungen: 0}, () => {
            fetchApi(MITARBEITER_UEBERSICHT).then( data => {
                var summen = data.reduce(function(akkumulator, obj) {
                    return {
                        red: akkumulator.red + obj.red,
                        yellow: akkumulator.yellow + obj.yellow,
                        green: akkumulator.green + obj.green,
                    }
                }, {red: 0, yellow: 0, green: 0 });

                let pie_data = []
                Object.entries(summen).forEach( ([key, value]) => {
                    if(value !== 0){
                        pie_data = [...pie_data, {title: key, value: value, color: color[key]}]
                    }
                })
                if (Array.isArray(pie_data) && pie_data.length === 0) {
                    console.log("leer");
                    pie_data = [{ title: 'NaN', value: 0, color: 'lightgrey'}]
                }

                this.setState({data: pie_data}, () => {
                    this.setState({maxUntersuchungen: this.state.data.reduce(function(sum, current) {
                        return sum + current.value;
                      }, 0)
                    })
                })
            })
        })

    
    }

    componentDidMount() {
        this.validate(this.props);
    }

    componentDidUpdate(newProps){
        if(newProps !== this.props){
            this.validate(newProps);
        }
    }

    onMouseEnter = (data, index) => {
        this.setState({ activeIndex: index });
    };

    onMouseLeave = () => {
        this.setState({ activeIndex: null });
    };

    setSelected(index){
        // console.log("klick", this.state.data, index)
        localStorage.setItem('detailSort', this.state.data[index].title)
        window.location = "#vorsorgenkartei_details";

    }

    getCell(index){
        let month =  moment().month();
        // console.log("Monat:", month)
        return <Cell key={`cell-${index}`} opacity={month === index ? 1 : 0.6}/>
    }

    customLabel(){
        return(
            <text offset="5" width="30" x="146.5" y="125" class="recharts-text recharts-label" text-anchor="middle" fill="black"><tspan x="146.5" dy="0.355em">5</tspan></text>
        );
    }

    render() {
        const data = [
            {
              name: 'JAN',
              uv: 40,
              pv: 24,
              amt: 24,
            },
            {
              name: 'FEB',
              uv: 30,
              pv: 13,
              amt: 22,
            },
            {
              name: 'MAR',
              uv: 20,
              pv: 9,
              amt: 12,
            },
            {
              name: 'APR',
              uv: 27,
              pv: 39,
              amt: 20,
            },
            {
              name: 'MAI',
              uv: 18,
              pv: 48,
              amt: 21,
            },
            {
              name: 'JUN',
              uv: 23,
              pv: 38,
              amt: 25,
            },
            {
              name: 'JUL',
              uv: 34,
              pv: 43,
              amt: 21,
            },
            {
              name: 'AUG',
              uv: 34,
              pv: 43,
              amt: 21,
            },
            {
              name: 'SEP',
              uv: 45,
              pv: 41,
              amt: 16,
            },
            {
              name: 'OKT',
              uv: 33,
              pv: 22,
              amt: 12,
            },
            {
              name: 'NOV',
              uv: 36,
              pv: 34,
              amt: 21,
            },
            {
              name: 'DEZ',
              uv: 24,
              pv: 33,
              amt: 21,
            },
          ];

        let donut = "";
        if(this.state.donut){
            donut = <>
            <div className={`d-flex align-items-center justify-content-center ${this.state.yearly ? `col-auto border-right` : 'col'}`} style={{borderColor: '#F1F0F0'}}>
                <div className={`row widget-piechart`}>
                    <div className="col">
                        <PieChart width={275} height={300}>
                            <Pie name="filler" data={[{value:100, name: "all"}]} dataKey="value" outerRadius={70} stroke="none" fill="#fff" />
                            <Pie 
                                data={this.state.data} 
                                dataKey="value" 
                                nameKey="name" 
                                stroke="none"
                                innerRadius={80} 
                                outerRadius={100} 
                                onMouseEnter={this.onMouseEnter}
                                onMouseLeave={this.onMouseLeave}
                                onClick={(_, index) => this.setSelected(index)}
                                fill="#82ca9d">
                                    {this.state.data.map((entry, index) => (
                                        <Cell 
                                            key={`cell-${index}`} 
                                            fill={ entry.color } 
                                            opacity={index === this.state.activeIndex ? '0.6' : '1'} 
                                            cursor={"pointer"}
                                        />
                                    ))}
                                <Label width={30} position="center"
                                    content={<CustomLabel value1={this.state.maxUntersuchungen} value2="UNTERSUCHUNGEN"/>}>
                                </Label>
                            </Pie>
                        </PieChart>
                    </div>
                    <div className="col-auto d-flex align-items-center">
                        <div className="pie-legend">
                            {   
                                this.state.data.map( (data, idx) => {
                                    let text = {red: "FÄLLIG", yellow: "BALD FÄLLIG", green: "GÜLTIG"}
                                    return (<div key={data.title} onClick={() => this.setSelected(idx)}>
                                        <label className="txt-caps-small-regular">{text[data.title]}</label>
                                        <p><span className={`legend-color ${data.title}`} /><span>{data.value}</span></p>
                                    </div>)
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            </>
        }

        let yearly = "";
        if(this.state.yearly){
            yearly = <>
            <div className="col">
                <div className="justify-content-center widget-overview">
                    <WidgetFilterBox />
                    <ResponsiveContainer width="100%" height={225}>
                        <BarChart
                            tabIndex={1}
                            width={500}
                            height={300}
                            data={data}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                            >
                            <CartesianGrid stroke="#F8F7F7" strokeDasharray="0" vertical={false} />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Bar dataKey="pv" stackId="a" fill={color.red}>
                                {data.map((entry, index) => this.getCell(index))}
                            </Bar>
                            <Bar dataKey="uv" stackId="a" fill={color.yellow}>
                                {data.map((entry, index) => this.getCell(index))}
                            </Bar>
                            <Bar dataKey="amt" stackId="a" fill={color.green}>
                                {data.map((entry, index) => this.getCell(index))}
                            </Bar>
                        </BarChart>                          
                    </ResponsiveContainer>
                </div>
            </div>
            </>
        }

        return (<>
            <div className="row">
                { donut }
                { yearly }
            </div>
        </>
        )
    }
}

export default PieChartOverview;