import moment from "moment";
import { MITARBEITER, MITARBEITER_UEBERSICHT, REPORT } from "../utils/TableConstanten";

const tables = {
    [MITARBEITER]: {
        nachname: "Nachname",
        vorname: "Vorname",
        geburtstag: 'Geburtstag',
        email: "E-Mail",
    },
    [REPORT]: {
        'firmen.id': "Firma Id (Intern)",
        'firmen.name': "Firma Name",
        'firmen.strasse': "Firma Strasse" ,
        'firmen.plz': "Firma PLZ", 
        'firmen.ort': "Firma Ort", 
        'untersuchungen.art': "Untersuchungs Art", 
        'untersuchungen.beurteilung' : "Untersuchungs Beurteilung",
        'untersuchungen.gemacht_am' : {
            label: "Untersuchung durchgeführt",
            format: 'DD.MM.YYYY'
        },
        'untersuchungen.naechster_termin' : {
            label: "Nachuntersuchung",
            format: 'MM/YY'
        },
        'untersuchungen.kommentar' : "Untersuchung Kommentar",
        'untersuchungen.status' : "Untersuchungs Status",
        'vorsorgekartei.bg_grundsatz': "Untersuchung BG Grundsatz", 
        'vorsorgekartei.anlass': "Vorsorge Anlass", 
        'vorsorgekartei.titel': "Vorsorge Titel", 
        'vorsorgekartei.vorsorgeart': "Vorsorge Art", 
        'vorsorgekartei.beschreibung': "Vorsorge Beschreibung", 
        'vorsorgekartei.ablaufsfrist': "Vorsorge Ablaufsfrist", 
        'firmenkartei.bg_grundsatz' : "BG Grundsatz",
        'firmenkartei.anlass': "Vorsorge (Firma) Anlass", 
        'firmenkartei.titel' : "Vorsorge (Firma) Titel",
        'mitarbeiter.nachname' : "Mitarbeiter Nachname",
        'mitarbeiter.vorname' : "Mitarbeiter Vorname", 
        'mitarbeiter.geburtstag': {
            label: "Mitarbeiter Geburstag",
            format: 'DD.MM.YYYY'
        },
        'mitarbeiter.email': "Mitarbeiter E-Mail", 
        'zuordnung.bezeichnung' : "Tätigkeit/Bereich: Bezeichnung",
        'zuordnung.typ': "Tätigkeit/Bereich Zuordnung Typ", 
    },
    [MITARBEITER_UEBERSICHT]: {
        'nachname': "Nachname",	
        'vorname': "Vorname",	
        'bezeichnung': "Tätigkeitsbereich",	
        'red': "Abgelaufen",	
        'yellow': "bald Ablaufend",	
        'green': "gültig",
    }
}



export function convertToCsv(data, table, fields = []){
    // console.log('convertFunction', data, typeof data);

    if(table in tables){
        return data.map(item => {
            let one = {}
            Object.entries(item).map(([key, value]) => {
                if(table !== "report"){
                    if(key in tables[table]){
                        one = {...one, [tables[table][key]]: value }
                    }else if(key === 'Tätigkeit/Bereich'){
                        if(Array.isArray(value)){
                            one = {...one, [key]: value.join(',')}
                        }else {
                            one = {...one, [key]: value}
                        }
                    }
                }else { //REPORT Export
                    // console.log(selectedFields, selectedFields.includes(key));
                    if(fields.includes(key) && key in tables[table]){
                        let val = value;
                        let label = tables[table][key]
                        // console.log("type:", typeof tables[table][key], tables[table][key]);
                        if(typeof tables[table][key] === "object"){
                            label = tables[table][key].label
                            if(tables[table][key].format){
                                val = moment(value).format(tables[table][key].format)
                            }
                        }

                        one = {...one, [label]: val }
                    }
                }
            })
            return one;
        })
    }
    return data; //durch ohne anpassung!
}