export default function CustomLabel({viewBox, value1, value2}){
    const {cx, cy} = viewBox;
    return (<>
        <text x={cx} y={cy -5} fill="black" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
            <tspan alignmentBaseline="middle" fontSize="36">{value1}</tspan>
        </text>
        <text x={cx} y={cy +20} fill="#B3ACA9" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
            <tspan alignmentBaseline="middle" fontSize="10">{value2}</tspan>
        </text>
    </>)
  }