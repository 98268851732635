import React, { Component } from 'react';
import { reportStandardCols, reportStandardColsMitarbeiter } from '../config/TableViewConfig';
import TableBox from './Table/TableBox';
import ToolBox from './ToolBox';
import { fetchApi } from '../utils/DatabaseHelper';
import { MITARBEITER } from "../utils/TableConstanten";

export default class ReportBox extends Component {
    constructor(props) {
        super(props);
        this.reportSchema = {
            type: 'object',
            properties: {}
        }
        this.state = { 
            possibleFields: [], 
            selectedFields: [],
        }

    }

    validate(props) {
        let selectedFields = localStorage.getItem('report_fields') === null ? reportStandardCols : JSON.parse(localStorage.getItem('report_fields'));
        let possibleFields = [];

        if(typeof this.props.detailId !== 'undefined'){
            selectedFields = localStorage.getItem('report_fields') === null ? reportStandardColsMitarbeiter : JSON.parse(localStorage.getItem('report_fields'));
        }

        this.reportSchema.properties = {}; //leeren vor aktualisierung
        Object.keys(props.schemas).map((resourceName) => {
            let schema = this.props.schemas[resourceName];
            Object.keys(schema.properties).forEach((propertie) => {
                
                if ((resourceName === 'authtokens' || resourceName === 'benutzer' || resourceName === "datei" || resourceName === "zuordnung_firmenkartei"
                    || resourceName === 'zuordnung_mitarbeiter' || resourceName === 'email' || resourceName === 'emailPool')) {
                    //do Nothing
                } else if (propertie.indexOf('Id') > -1) {
                    //do Nothing
                } else {
                    // console.log('prop', propertie)
                    possibleFields.push(resourceName + "." + propertie);

                    //nur selectedFields als schema Felder übergeben
                    if (selectedFields.indexOf(resourceName + "." + propertie) !== -1) {
                        let tmpType = schema.properties[propertie]['type'][0] === "i" ? "integer" : schema.properties[propertie]['type'][0];
                        let tmp = { [resourceName + "." + propertie]: { type: tmpType } };
                        this.reportSchema.properties = { ...this.reportSchema.properties, ...tmp };
                    }
                }
            });
        });
        this.setState({ possibleFields: possibleFields });


    }

    componentDidMount() {
        this.validate(this.props);

        //Setzte Titel der Componente
        fetchApi(MITARBEITER, this.props.detailId).then( data => {
            // console.log("data", data)
            if(data.nachname !== undefined){
                let titel = data.nachname + ', ' + data.vorname;
                this.props.changeTitle(titel);
            }else {
                let storeValue = localStorage.getItem("detailSort");
                if(storeValue){
                    let title = "";
                    switch (storeValue) {
                        case 'red':
                            title = "Fällige ";
                            break;
                        case 'yellow':
                            title = "Bald fällige ";
                            break;
                        case 'green':
                            title = "Gültige ";
                            break;
                        default:
                            break;
                    }
                    this.props.changeTitle(title + "Untersuchungen");
                }
            }
        }).catch(err => console.log(err));

    }

    componentDidUpdate(newProps){
        if(newProps !== this.props){
          this.validate(newProps)
        }
      }

    componentWillUnmount(){
        this.props.changeTitle(null);
    }


    render() {
        // console.log('ketest schema:', this.reportSchema)
        let checkMitarbeiterDetails = typeof this.props.detailId !== 'undefined';
        let content = <div className="d-none">Leer</div>;
        if (this.state.possibleFields.length > 0) {
            content =
                <TableBox
                    title="Übersicht"
                    resourceName={"report"}
                    detailId={this.props.detailId}
                    schema={this.reportSchema}
                    fields={this.state.possibleFields}
                    rowClicks={true}
                    toolBox={<ToolBox fields={this.state.possibleFields} updateReport={() => this.validate(this.props)} mitarbeiterDetails={checkMitarbeiterDetails} />} report={true} />;
        }
        return (content);
    }
}



