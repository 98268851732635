export default {
    'firmen': { firmen_name : 'name' },
    'vorsorgekartei': { 
        bg_grundsatz:   "bg_grundsatz",        
        anlass:         "anlass",
        titel:           "titel",
        vorsorgeart:    "vorsorgeart",        
        beschreibung:   "beschreibung",        
        ablaufsfrist:   "ablaufsfrist"        
    },
    'mitarbeiter': {nachname: 'nachname', vorname: 'vorname'},
    'zuordnung': {name: 'name'},
    'firmenkartei': {}, //bg_grundsatz: 'BG Grundsatz', titel: 'titel'
    'untersuchungen': {
        id : 'id',
        untersuchungenId: "untersuchungenId"
    },
    'datei': {
        name: 'name',
    },
    'mitarbeiter_zuordnung': {
        'Tätigkeit/Bereich': 'bezeichnung',
        'Kommentar': 'kommentar'
    }
}