import { Component } from "react";
import { fetchApi, putApi } from "../../utils/DatabaseHelper";
import MailForm from "../AutoMailing/MailForm";
import ReactForm from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import { toast } from 'react-toastify';
import LoadingSpinner from "../../utils/Loading";
import * as tabConst from '../../utils/TableConstanten'
import mailingTriggers from "../AutoMailing/trigger/index";

const uiSchema = {
    "text": {
        "ui:widget": "textarea",
        "ui:options": {
            "rows": 5
        }
    },
    "readonly": {
        "ui:readonly": true
    },
    "pass": {
        "ui:widget": "password",
    },
    "secure": {
        "ui:widget": "checkbox"
    },
};


class MailingSettings extends Component {
    
    constructor(props) {
        super(props);
        this.default_template = {
            trigger: '',
            content: '',
            data: '',
        };
        this.state = { 
            loadingMails: true,
            emails: [ ], 
            mailSettings: {},
            mailTestSettings: {
                email: "kai@sobek-innovations.de" //Test/Default wert
            },
            alert: '',
            testingModalShow: false,
            showLoading: false
        };
        this.addTemplate = this.addTemplate.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleAuthInputChange = this.handleAuthInputChange.bind(this);
        this.saveOptions = this.saveOptions.bind(this);
        this.reactFormonInputchange = this.reactFormonInputchange.bind(this);
        this.reactTestFormOnInputchange = this.reactTestFormOnInputchange.bind(this);
        this.handleCollapse = this.handleCollapse.bind(this)
        this.toggleTestModal = this.toggleTestModal.bind(this)
        this.sendTestMail = this.sendTestMail.bind(this)


    }
    
    async validate(props){
        //Mail Settings
        if(typeof props.settings !== 'undefined'){
            this.setState({mailSettings: props.settings});
        }
        //Set vorhandene E-Mail einstellungen mit den gegebenen Taetikeitsbereichen
        this.setState({taetigkeiten: await this.setTaetigkeitsbereiche()}, async () => {
            await fetchApi("emails").then(data => {
                const emails = data.rows.map((email) => {
                    email.formSchema = mailingTriggers[email.trigger]?.form_schema || {};
                    email.uischema = mailingTriggers[email.trigger]?.uiSchema || {};
                    email.transformErrors = mailingTriggers[email.trigger]?.transformErrors || {};
                
                    if (email.trigger === 'comingUpExamination') {
                        email.formSchema.definitions.taetigkeit.items.anyOf = this.state.taetigkeiten || [];
                    }
                    return email;
                });
                this.setState({ emails: emails, loadingMails: false });
            }).catch(err => {
                console.log(err);
            })
        });
    }

    componentDidMount() {
        global.emitter.on('REFRESH_EMAIL', () => {
            this.validate(this.props);
            let accordions = document.querySelectorAll('.accordion .show');
            if(accordions.length > 0){
                for (let i = 0; i < accordions.length; i++) {
                    accordions[i].classList.remove('show');
                    
                }
            }
            this.forceUpdate()
        });

        this.validate(this.props);
    }
    componentDidUpdate(newProps) {
        if(newProps !== this.props){
            this.validate(newProps);
        }
    }

    addTemplate(){
        this.setState((state) => {
            return { emails: [...state.emails, this.default_template]  }
        })
    }

    handleAuthInputChange(e){
        this.setState({ mailSettings: {...this.state.mailSettings, auth: {...this.state.mailSettings.auth, [e.target.id]: e.target.value} }}, () => console.log(this.state))    
    }
    handleInputChange(e){
        if(e.target.type === 'checkbox'){
            this.setState({ mailSettings: {...this.state.mailSettings, [e.target.id]: e.target.checked }}, () => console.log(this.state))    
        }else {
            this.setState({ mailSettings: {...this.state.mailSettings, [e.target.id]: e.target.value }}, () => console.log(this.state))
        }
    }

    async setTaetigkeitsbereiche() {
        let query = global.filterFirmenId !== 0 ? "?firmenId=" + global.filterFirmenId : "";
        let zu = await fetchApi(tabConst.ZUORDNUNG, query);
    
        const zuordnungenPromises = Object.keys(zu).map(async (key) => {
            let zuordnung = zu[key];
            let firma = await fetchApi(tabConst.FIRMEN, zuordnung.firmenId);
            return {
                const: zuordnung.id.toString(),
                title: 'Firma: ' + firma.name + ' Bereich: ' + zuordnung.bezeichnung
            };
        });
    
        const firmenTaetigkeiten = await Promise.all(zuordnungenPromises);
        // console.log(firmenTaetigkeiten);
        return firmenTaetigkeiten;
    }


    reactTestFormOnInputchange(event){
        this.setState({ mailTestSettings: event.formData })
    }

    reactFormonInputchange(event){
        //timeout
        clearTimeout(this.editTimer);
        this.editTimer = setTimeout(() => {
            this.setState({ mailSettings: event.formData })
        }, 1000);
        // console.log('inpChan', event.formData )
    }


    handleCollapse(e){
        let body = document.querySelector('.mail-settings div.card')
        body.classList.toggle('collapsed')
    }

    saveOptions(e){
        console.log('save this', 'MailSettings', {widget_config: JSON.stringify(this.state.mailSettings)})
        putApi("einstellungen", 'MailSettings', {widget_config: JSON.stringify(this.state.mailSettings)} )
            .then(resp => {
                // console.log('ketest', resp)
                if(resp.status === 200){ toast.success(resp.message)
                } else { 
                    toast.error("Leider ist ein Fehler aufgetreten. Probieren Sie es noch einmal.")
                    console.error('put Einstellungen:', resp.message)
                }
            })
            .catch(err => {
                console.error('put Einstellungen err:', err)
            })
    }


    toggleTestModal(e){
        this.setState({ testingModalShow: !this.state.testingModalShow })
    }

    sendTestMail(e){
        console.log("TestMail mit infos:", this.state.mailTestSettings);

        this.setState({showLoading: true}, () => {
            fetchApi('mails/sendTest', '?', `empfaenger=${this.state.mailTestSettings.email}`).then(response => {
                console.log("resp:",response);
    
                if(response.code === 200){
                    toast.info(response.message, {
                        autoClose: 5000,
                    });
                }else {
                    toast.error('Fehler beim Senden, bitte einstellungen Prüfen', {
                        autoClose: 5000,
                        });
                }
            }).then( () => {
                this.setState({testingModalShow: false, showLoading: false});
            })
        });


    }


    render() {

        if(typeof this.state.mailSettings === 'undefined'){
            return <div style={{position: "relative"}}><LoadingSpinner show={true} /></div>
        }
        let mailSettingsSchema = {
            "type": "object",
            "properties": {
                "Send via":{
                    "type": "string",
                    "enum": [
                        "Office 365",
                        "SMTP"
                    ],
                    // "default": "Office 365"
                },
            },
            "required": ["Send via"],
            "dependencies": {
                "Send via": {
                    "oneOf": [
                        {
                            "properties":{
                                "Send via":{
                                    "enum": ['Office 365']
                                },
                                "user": {
                                    "type": "string",
                                    "title": 'User',
                                },
                                "pass":{
                                    "type": "string",
                                    "title": "Passwort"
                                }
                            },
                            "required": ["user", "pass"]
                        },
                        {
                            "properties": {
                                "Send via":{
                                    "enum": ["SMTP"]
                                },
                                "host": {
                                    "type": "string",
                                    "title": "Server"
                                },
                                "port":{
                                    "type": "number",
                                    "title": "Port"
                                },
                                "secure":{
                                    "type": "boolean",
                                    "title": "SSL",
                                    // "description": "Check für Port: 465, ansonsten nicht"
                                },
                                "user": {
                                    "type": "string",
                                    "title": 'Benutzer',
                                    "autofill": "off"
                                },
                                "pass":{
                                    "type": "string",
                                    "title": "Passwort",
                                    "autofill": "off"
                                }
                            },
                            "required": ["user", "pass","host","port","secure"]
                        }
                    ]
                }
            }
        }
        let mailSettingsForm = (<div className="wrapperMailSettings">
            <ReactForm
                id="MailSettings"
                autofill="off"
                validator={validator}
                schema={mailSettingsSchema}
                formData={this.state.mailSettings}
                uiSchema={uiSchema}
                onSubmit={this.saveOptions}
                onChange={this.reactFormonInputchange}
            >
                <button type="submit" className="btn btn-primary">Speichern</button>
            </ReactForm>
            <button style={{position: 'absolute', bottom: "20px", right: "20px"}} onClick={this.toggleTestModal} className="btn btn-secondary">Testen</button>
        </div>)

        let mailAccordions = <div style={{position: "relative"}}><LoadingSpinner show={true} /></div>
        
        if(!this.state.loadingMails) {
            mailAccordions = this.state.emails.map((email, idx) => {
                return <MailForm email={email} key={idx} taetigkeiten={this.state.taetigkeiten} />
            })
        }

        return (<>
        <div className="row">
            <div className="col mail-settings">
                <div className="heading row">
                    <h5 className="col">E-Mail Einstellungen</h5>
                </div>
                <hr/>
                <div className="card collapsed">
                    <div className="card-body">
                        <span className="closeArea" onClick={this.handleCollapse}><i className="fas fa-chevron-up"></i></span>
                        {mailSettingsForm}
                    </div>  
                </div>
                { this.state.alert }
            </div>
        </div>
        <div className="row">
            <div className="col widget-config-mail">
                <div className="heading row">
                    <h5 className="col">Automatische E-Mails</h5>
                    <div className="control col-auto">
                        <button className="btn btn-block btn-sm btn-default" onClick={this.addTemplate}><i className="fas fa-plus"></i></button>
                    </div>
                </div>
                <hr/>
                <div className="accordion" id="accordionEmails">
                    { mailAccordions }
                </div>
            </div>
        </div>
        <div className={"modal fade " + (this.state.testingModalShow ? 'show' : '')} id="modal-default" aria-modal="true" role="dialog" aria-hidden="true" style={this.state.testingModalShow ? { paddingRight: 15, display: 'block' } : {}}>
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">E-Mail Einstellungen Testen</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {
                  this.setState({ testingModalShow: false })
                }} >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <ReactForm
                    id="MailTest"
                    autofill="off"
                    validator={validator}
                    schema={{
                        type: 'object',
                        required: ['email'],
                        properties: {
                            email: { type: 'string', title: 'E-Mail', default: this.state.mailTestSettings.email },
                        }
                    }}
                    formData={this.state.mailTestSettings}
                    uiSchema={uiSchema}
                    onChange={this.reactTestFormOnInputchange}
                    onSubmit={ this.sendTestMail }
                >
                    <button type="submit" className="btn btn-primary">Senden</button>
                </ReactForm>
                <LoadingSpinner show={ this.state.showLoading }/>
              </div>
            </div>
          </div>
        </div>
        </>)
    }
}

export default MailingSettings;