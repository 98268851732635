import React, { Component } from "react";
import { fetchApi } from "../../utils/DatabaseHelper";
import CustomSelect from "../SelectField/CustomSelect";

class WidgetFilterBox extends Component{
    state = {
        untersuchungen: [
            {name: "Alle", id: 0},
            {name: "Erledigt", id: 1},
            {name: "in Arbeit", id: 2},
            {name: "Offen", id: 3},
        ],
        years: [
            {name: "2024", id: 2024},
            {name: "2023", id: 2023},
            {name: "2022", id: 2022},
            {name: "2021", id: 2021},
        ],
        defaultSelectText: "Please select an option",
    }

    validate(props) {
        fetchApi("einstellungen", "SendMail").then( data => {
            // console.log('ketest', data.toMail)
            this.setState({ toMail: data.toMail });
        })

    }

    componentDidMount() {
        this.validate(this.props);
    }

    
    render() {
        return (<>
            <div className="widget-toolbox row">
                <div className="col">
                    <CustomSelect 
                        selectLabel="Untersuchungen:"
                        optionsList={this.state.untersuchungen}
                    />
                </div>
                <div className="col-auto">
                    <CustomSelect 
                        selectLabel="Status:"
                        optionsList={this.state.untersuchungen}
                    />
                </div>
                <div className="col-auto d-flex justify-content-center">
                    <CustomSelect 
                        border={true}
                        arrowIconClass="far fa-calendar-alt"
                        optionsList={this.state.years}
                    />
                </div>
            </div>
        </>)
    }
}
  

export default WidgetFilterBox;