/**
 * Config welche Spalten in einer Tabelle angezeigt werden, mit welchem Namen
 */

//felder die generell ausgeblendet werden sollen
//hier wird in der Table.js beim heading und cell geprüft ob hier enthalten, wenn ja wird d-none als class geadded
export var hideByAll = [
    'id',   
    'pwd',
]

// Umbenennungen generell
export var renameColName = {
    bg_grundsatz: {label: 'BG Grundsatz'},
    gemacht_am: {label: 'durchgeführt'},
    naechster_termin: {label: 'Nachuntersuchung'},
    bezeichnung: {label: 'Tätigkeitsbereich'},
}


export var formatDate = {
    gemacht_am: 'MM/YYYY',
    naechster_termin: 'MM/YYYY',
    geburtstag: 'DD.MM.YYYY',
}

//Feste Spalten in der Report(Vorsorgekartei) Tabelle 
export var reportStandardCols = [
    // "mitarbeiter.id",
    "mitarbeiter.nachname",
    "mitarbeiter.vorname",
    // "firmenkartei.id", 
    "firmenkartei.titel", 
    "firmenkartei.bg_grundsatz",
    // "untersuchungen.id", 
    "untersuchungen.beurteilung", 
    "untersuchungen.gemacht_am", 
    "untersuchungen.naechster_termin", 
    "untersuchungen.kommentar",
    "zuordnung.bezeichnung"
]

export var reportStandardColsMitarbeiter = [
    // "mitarbeiter.id",
    // "mitarbeiter.nachname",
    // "mitarbeiter.vorname",
    // "firmenkartei.id", 
    "firmenkartei.titel", 
    "firmenkartei.bg_grundsatz",
    // "untersuchungen.id", 
    "untersuchungen.beurteilung", 
    "untersuchungen.gemacht_am", 
    "untersuchungen.naechster_termin", 
    "untersuchungen.kommentar",
    "zuordnung.bezeichnung"
]

//Feste Spalten fpr report Mitarbeiter_uebersicht
export var reportMitarbeiterUebersicht = [
    "nachname",
    "vorname",
    "bezeichnung",
    "statistik"
]

// alter wert   : neuer wert
export var toolBoxTabRename = {
    zuordnung: 'Tätigkeit/Bereich', 
    firmenkartei: "Vorsorgen/Eignungen",
}
export var toolBoxColRename = {
    bg_grundsatz: "BG Grundsatz",
    gemacht_am: "durchgeführt",
    naechster_termin: "Nachuntersuchung"
}


//Formular/Modal renames
export var modalTitleRename = {
    vorsorgekartei: "Untersuchungsliste Vorsorgen/Eignungen",
}

//Rename der Form Labels
export var formInputRename = {
    bg_grundsatz: "BG Grundsatz",
}


//Tabellen Standart Sortier angaben
export var tableDefaultSort = {
    'mitarbeiter': { 
        'sortOrder': 'ASC',
        'orderBy': 'nachname'
    },
}