/**
 * 
 * @param {Resouce Name} resName 
 * @param {id for single, can be empty} resourceId 
 * @param {query Params} query 
 */
export async function fetchApi(resName, resourceId = '', query = ''){
    let headers = {
        method: "GET",
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': global.auth.authToken.token,
            'firmenid': global.filterFirmenId
        } 
    }
    if(resourceId !== '') resourceId = '/'+ resourceId;

    return new Promise((resolve, reject) => {
        // console.log(global.api_url + resName + resourceId + query, headers)
        fetch(global.api_url + resName + resourceId + query, headers)
        .then(res => res.json())
        .then(response => {
            if(response.status === 404 || response.status === 500){ //not found
                return reject(response)
            }
            
            if(response.data){ //data
                return resolve(response.data);
            }
            return resolve(response); //full 
        })
        .catch(err => {
            return reject(err);
        })
    })
}

//WIr Updaten einen Eintrag
export async function putApi(resName, resourceId = '', data){
    let headers = {
        method: "PUT",
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': global.auth.authToken.token 
        } 
    }
    headers.body = JSON.stringify(data);

    // console.log('ketest', headers.body)

    if(resourceId !== '') resourceId = '/'+ resourceId;

    return new Promise((resolve, reject) => {
        fetch(global.api_url + resName + resourceId, headers)
            .then(res => res.json())
            .then(response => {
                return resolve(response)
            })
            .catch(err => {
                return reject(err);
            })
    })
}

export async function saveApi(resName, data){
    let headers = {
        method: "POST",
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': global.auth.authToken.token 
        } 
    }
    headers.body = JSON.stringify(data);
    console.log(global.api_url + resName)
    return new Promise((resolve, reject) => {
        fetch(global.api_url + resName, headers)
            .then(res => res.json())
            .then(response => {
                return resolve(response)
            })
            .catch(err => {
                console.log('err', err)
                return reject(err);
            })
    })
}


export async function deleteApi(resName, resourceId){
    let headers = {
        method: "DELETE",
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': global.auth.authToken.token,
            'firmenid': global.filterFirmenId
        } 
    }

    return new Promise((resolve, reject) => {
        fetch(global.api_url + resName +"/" + resourceId, headers)
            .then(res => res.json())
            .then(response => {
                console.log('delete ok')
                return resolve(response)
            })
            .catch(err => {
                console.log('err', err)
                return reject(err);
            })
    }) 
}