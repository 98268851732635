import React, { Component } from 'react';
import Table from './Table';
import Box from '../Box';

export default class TableBox extends Component {
  state = {showFilter: false}

  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
  }

  validate(props) {
    // console.log('has in table', props);
    this.setState({ ...props  });

  }
  componentDidMount() {
    this.validate(this.props);
    
    global.emitter.on('REFRESH_' + this.props.resourceName.toUpperCase(), () => {
      this.validate(this.props);
    });
  }
  componentDidUpdate(newProps){
    if(newProps !== this.props){
      this.validate(newProps)
    }
  }

  render() {
    if (this.state.resourceName && this.state.schema) {
      return (
        <Box 
          title={this.state.title ? this.state.title : this.state.resourceName} 
          onFilter={() => this.setState({showFilter: !this.state.showFilter}, () => {if (this.tableRef && this.tableRef.current) this.tableRef.current.validate() }) }
          onRowNew={() => {
            if (!this.props.report) {
              global.emitter.emit('SHOW_MODAL', {schema: this.state.schema, data:{resourceName: this.state.resourceName}, lastModalData: this.props.lastModalData, onClose: () => {
                if (this.tableRef && this.tableRef.current) this.tableRef.current.validate();
              }});
            }
          }}
          onSearch={(search) => this.setState({search})}
          disableSearch={this.props.disableSearch ? true : false}
          disableFilter={this.props.disableFilter ? true : false}
          toolBox={this.props.toolBox}
          >
          <Table
            resourceName={this.state.resourceName}
            detailId={this.props.detailId}
            relatedResourceId={this.props.relatedResourceId}
            schema={this.state.schema}
            ref={this.tableRef}
            search={this.state.search}
            fields={this.state.fields}
            showFilter={this.state.showFilter}
            rowClicks={this.props.rowClicks ? this.props.rowClicks : false}
            excludeFields={this.props.excludeFields ? this.props.excludeFields : []}
            renderers={this.props.renderers ? this.props.renderers : {}}
            onRowEdit={id => {
              global.emitter.emit('SHOW_MODAL' + (global.modal ? '_FALLBACK' : ''), {data: {resourceId: id, resourceName: this.state.resourceName}, onClose: () => {
                if (this.tableRef && this.tableRef.current) this.tableRef.current.validate();
                this.setState({resourceId: undefined});
              }});
              
            }}
            report={this.state.report}
          ></Table> 
          
        </Box>
      );
    } else {
      return <span></span>
    }
  }
}




