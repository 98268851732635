export const transformErrors = (errors) => {
  return errors.map((error) => {
    console.log("err:", error);
    if (error.name === "minItems" && error.property === ".to.taetigkeit") {
      error.message = `Es muss mindestens ${error.params.limit} Bereich ausgewählt sein.`;
    }
    if(error.name === "oneOf"){
        error.message = ''
    }
    return error;
  });
};

export let comingUpExamination = {
  label: "Untersuchungen stehen an",
  desc: "",
  form_schema: {
    definitions: {
      taetigkeit: {
        title: "für welchen Tätigkeitsbereich",
        minItems: 1,
        type: "array",
        uniqueItems: true,
        description:
          "Nur ausgewählte Tätigkeitsbereiche werden geprüft (für alle Tätigkeitsbereiche, bitte auch Alle markieren)",
        items: {
          anyOf: [
            { const: 1, title: "My Foo" },
            { const: 2, title: "My Foo" },
          ],
        },
      },
    },
    type: "object",
    required: ["to", "when"],
    properties: {
      cron: {
        title: "In welchem Intervall soll die E-Mail gesendet werden?",
        type: "string",
        description:
          "CRON Zeiteinstellung ( https://crontab.guru/ ) DEFAULT = '00 04 1 * *'",
        default: "00 04 1 * *",
      },
      from: {
        type: "string",
        title: "Sender E-Mail",
        default: "info@divoka.de",
      },
      to: {
        type: "object",
        title: "Empfänger",
        oneOf: [
          {
            title: "Mitarbeiter",
            type: "object",
            properties: {
              mitarbeiter: {
                type: "string",
                title: " E-Mail",
                readOnly: true,
              },
              taetigkeit: { $ref: "#/definitions/taetigkeit" },
            },
          },
          {
            title: "Individuell",
            type: "object",
            properties: {
              email: {
                type: "string",
                title: "E-Mail Adresse",
              },
              taetigkeit: { $ref: "#/definitions/taetigkeit" },
            },
            required: ["email", "bereich"],
          },
        ],
      },
      when: {
        title: "Welcher Zeitraum soll beachtet werden?",
        type: "object",
        properties: {
          zahl: {
            title: "x vor Ablauf",
            type: "number",
            description:
              "Ablauf ist das Datum wann die Untersuchung nicht mehr Gültig ist.",
          },
          auswahl: {
            title: "Tage/Monate",
            type: "string",
            enum: ["Tage", "Monate"],
          },
        },
        required: ["zahl", "auswahl"],
      },
      subject: {
        type: "string",
        title: "Betreff",
      },
      text: {
        type: "string",
        title: "E-Mail Text",
      },
    },
  },
  uiSchema: {
    text: {
      "ui:widget": "textarea",
      "ui:options": {
        rows: 5,
      },
    },
    readonly: {
      "ui:readonly": true,
    },
    cron: {
      "ui:help": "Um 04:00, an Tag 1 des Monats",
    },
    to: {
      "ui:classNames": "si-oneOf",
      mitarbeiter: {
        "ui:placeholder": "E-Mail geht an den betreffenden Mitarbeiter",
      },
    },
  },
  function: trigger,
  transformErrors: transformErrors,
  mail_context: ["{mitarbeitername} - nur bei Empfänger an Mitarbeiter"],
  context_functions: [
    "{ma-list}",
    "{untersuchung-list} - nicht bei Individuellem Empfänger",
  ],
};

export function trigger() {
  console.log("comingUpExamination Trigger!!!!");
}
