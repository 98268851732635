import React, { Component } from 'react';

class Content extends Component {
  state = { schema: {}, data: {} }

  validateData() {

  }
  componentDidMount() {
    this.validateData(this.props);
  }
  // componentDidUpdate(newProps){
  //   if(newProps !== this.props){
  //     this.validateData(newProps)
  //   }
  // }

  render() {

    return (
      <section className="content">
        <div className="container-fluid">
            {this.props.children}
        </div>
      </section>
    );
  }
}

export default Content;
