import React, { Component } from "react";

class Pagination extends Component{
    state = {
        maxPages: this.props.maxPages, 
        currentPage: this.props.currentPage,
    }

    componentDidMount() {
        global.emitter.on('REFRESH_PAGINATION', async () => {
        //   console.log('ketest refresh pagination', this.props.maxPages, this.props.currentPage)
          this.setState({maxPages: this.props.maxPages, currentPage: this.props.currentPage});
        });
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }

    render() {
        let pages= [];
        for (let index = 0; index < this.state.maxPages; index++) {
            pages.push(<li key={index} className="page-item"><button value={index+1} className="page-link page" disabled={this.state.currentPage === index}
            onClick={this.props.page}
            > {index+1} </button></li>);
        }
    
        //Anzeige der Seitenanzahl
        let max = 3;
        let pa = [];
        if(this.state.currentPage >= max){
            pa = pages.slice( this.state.currentPage-(max-1), this.state.currentPage +max)
        }else{
            pa = pages.slice(0,this.state.currentPage +max)
        }

        // console.log('ketest', this.state.maxPages)
        return (
        <div className="pagination-wrapper">
            <div className="btn-group dropup">
                <button type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Sätze pro Seite { window.localStorage.getItem('ipp') }
                </button>
                <div className="dropdown-menu">
                    <button className="dropdown-item" onClick={this.props.changeItemsPerPage} value="10">10 Sätze</button>
                    <button className="dropdown-item" onClick={this.props.changeItemsPerPage} value="25">25 Sätze</button>
                    <button className="dropdown-item" onClick={this.props.changeItemsPerPage} value="50">50 Sätze</button>
                </div>
            </div>
            <ul className="pagination pagination-sm m-0 float-right" disabled={this.state.maxPages === 1}>
                <li className="page-item">
                    <button className="page-link w-auto" value="1" onClick={this.props.page}>Erste Seite</button>
                </li>
                <li className="page-item">
                    <button className="page-link" disabled={this.state.currentPage === 0} onClick={this.props.forward}><i className="fas fa-angle-left"></i></button>
                </li>
                { pa }
                <li className="page-item">
                    <button className="page-link" onClick={this.props.next} disabled={this.state.currentPage === (this.state.maxPages-1)}><i className="fas fa-angle-right"></i></button>
                </li>
                <li className="page-item">
                    <button className="page-link w-auto" value={this.state.maxPages} onClick={this.props.page}>Letzte Seite ({this.state.maxPages})</button>
                </li>
            </ul>
        </div>

        )
    }
}

export default Pagination;