import { FIRMENKARTEI, REPORT, VORSORGEN, ZUORDNUNG, MITARBEITER_UEBERSICHT } from "../utils/TableConstanten"

//indx = label      link = #link
export var menuConfig = {
    'Dashboard': {link: '#dashboard', icon:"sii-dashboard"},
    'Vorsorgekartei': {link: '#vorsorgenkartei', icon:"sii-vorsorgekartei"},
    'Mitarbeiter': {link: '#mitarbeiter', icon:"sii-mitarbeiter"},
    'Vorsorgen/Eignungen': {link: '#vorsorgen-eignungen', icon:"sii-vorsorgen-eignungen"},
    'Tätigkeiten/Bereiche': {link: '#taetigkeiten-bereiche', icon:"sii-tatigkeiten"}
}

// resourceName    Label
export var adminMenuConfig = {
    'vorsorgekartei': {label: "Untersuchungsliste", link: "#untersuchungsliste", icon:"sii-untersuchungsliste"},
    'firmen': {label: 'Firmen', link: '#firmen', icon:"sii-firmen"},
    'untersuchungen': {label: 'Untersuchungen', link: '#untersuchungen', icon:"sii-untersuchungen"},
    'benutzer': {label: 'Benutzer', link: '#benutzer', icon:"sii-benutzer"},
}

//erweiterte Links
export var menuErweitertConfig = {
    einstellungen : {label: 'Einstellungen', link: '#einstellungen', icon:"sii-einstellungen"},
    help : {label: 'Benötigen Sie Hilfe?', link: '#hilfe', icon:"sii-hilfe"}
}

//Link namen anpassen aber auf das richtige scheme/resourceName schieben und ggf. schön labeln
//    link name              resourceName                     schönes Titel Label auf single Seite
//                                                              das label zieht auch für die TabelBox
export var hashToSchema = {
    'untersuchungsliste': {schemaResourceName: VORSORGEN, label: "Untersuchungsliste"},
    'vorsorgenkartei_details': {schemaResourceName: REPORT, label: "Report"},
    'vorsorgenkartei': {schemaResourceName: MITARBEITER_UEBERSICHT, label: "Vorsorgekartei"},
    'vorsorgen-eignungen': {schemaResourceName: FIRMENKARTEI, label: 'Vorsorgen/Eignungen'},
    'taetigkeiten-bereiche': {schemaResourceName: ZUORDNUNG, label: 'Tätigkeiten und Bereiche'},
}
